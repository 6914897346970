
import domready from 'domready';
import MD5 from "crypto-js/md5";
import Fingerprint2 from 'fingerprintjs2';

var API_BASE = "/app/"
var timeout = null;

function fetchKey() {

    let params = { gimme: "a key" };
    if (! document.getElementById("uploader-key") ) {
        console.log("Not fetching key, no uploader.")
        return;
    }
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
        body: JSON.stringify( params )  
    };
    fetch( API_BASE + 'submit', options )
    .then( response => response.json() )
    .then( response => {
        let salt = response.salt;
        // console.log(response);
        let hash=MD5(salt + "SKELETONS").toString();
        document.getElementById("uploader-key").value = hash;
        document.getElementById("fpuploader-key").value = hash;
        console.log(hash);

        // Do something with response.
    } ).catch((error) => { setTimeout(fetchKey, 5000) } ) ;
}

function fblAlert(text, seconds=5) {
    if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
    }
    
    let alert = document.getElementById("uploader-results");
    if (!alert) { 
        return;
    }
    alert.classList.remove("hidden");
    alert.innerHTML = text;
    timeout = setTimeout(fblHideAlert, seconds * 1000);
}

function fblHideAlert() {
    if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
    }

    let alert = document.getElementById("uploader-results");
    if (!alert) { 
        return;
    }
    alert.classList.add("hidden");
}

function setupHandlers(token) {
    let uploader = document.getElementById(token);

    if (! uploader) {
        console.log("No uploader",token,"found.")
        return;
    }
    uploader.addEventListener('submit', (e) => {
        // on form submission, prevent default
        e.preventDefault();
      
        // construct a FormData object, which fires the formdata event
        let data = new FormData(uploader);

        const options = {
            method: 'POST',
            body: data  
        };

        console.log(options);
        console.log(JSON.stringify(data));
        fblAlert("Uploading...");

        fetch( API_BASE + 'submit', options )
        .then( response => response.json() )
        .then( response => {
            console.log(response)
            if (response.result=="success") {
                fblAlert("Success! It should begin printing within 15-30 seconds.", 30)
            } else if (response.result=="ocr-failure") {
                fblAlert("There was a problem scanning your image for text. Did you upload a regular image (JPEG or PNG)?", 30);
            } else if (response.result=="file-type-failure") {
                fblAlert("Can you upload an image, such as a JPEG or PNG?", 30);
            }

        } )
        .catch((error) => {
            fblAlert("A miscellaneous error occurred; are you online?", 30);
        });
      });
}

// Lol this is a late-night decision


function setupHandlerText(token) {
    let uploader = document.getElementById(token);
    if (! uploader) {
        console.log("No uploader",token,"found.")
        return;
    }

    uploader.addEventListener('submit', (e) => {
        // on form submission, prevent default
        e.preventDefault();
      
        // construct a FormData object, which fires the formdata event
        let data = new FormData(uploader);

        const options = {
            method: 'POST',
            body: data  
        };
        console.log(options);
        console.log(JSON.stringify(data));
        fblAlert("Uploading...");

        fetch( API_BASE + 'submit_text', options )
        .then( response => response.json() )
        .then( response => {
            console.log(response)
            if (response.result=="success") {
                fblAlert("Success! It should begin printing within 15-30 seconds.", 30)
            } else if (response.result=="no-data") {
                fblAlert("No data was received by the server. Odd.", 30);
            }

        } )
        .catch((error) => {
            fblAlert("A miscellaneous error occurred; are you online?", 30);
        });
      });
}
function renderFingerprint(fingy) {
    var html = "";
    var text = "";
    console.log(fingy);
    for (var index of Object.keys(fingy)) {
        let key = fingy[index].key;
        let val = fingy[index].value;
        text += key + ": " + JSON.stringify(val) + "\n";
        html += "<strong>" + key + "</strong>: " + JSON.stringify(val) + "<br/>";
    }
    document.getElementById("fingerprint-demo").innerHTML = html;
    // document.getElementById("fingerprint-demo").dataset.printable = text;
    document.getElementById("fpuploader-data").value = text;    

}

function tryFingerprint() {
    var options = { excludes: {canvas: true, webgl: true, } };

    if (window.requestIdleCallback) {
        requestIdleCallback(function () {
            Fingerprint2.get(options, function (components) {

                renderFingerprint(components);
            })
        })
    } else {
        setTimeout(function () {
            Fingerprint2.get(options, function (components) {
                renderFingerprint(components);
                
                
            })  
        }, 500)
    }



}


domready(function() {  
    fetchKey();
    setupHandlers("uploader");
    setupHandlerText("fpuploader");
    fblHideAlert();
    tryFingerprint();
});
